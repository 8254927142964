// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-agile-js": () => import("./../../../src/pages/projects-agile.js" /* webpackChunkName: "component---src-pages-projects-agile-js" */),
  "component---src-pages-projects-codam-js": () => import("./../../../src/pages/projects-codam.js" /* webpackChunkName: "component---src-pages-projects-codam-js" */),
  "component---src-pages-projects-emerging-industries-js": () => import("./../../../src/pages/projects-emerging-industries.js" /* webpackChunkName: "component---src-pages-projects-emerging-industries-js" */),
  "component---src-pages-projects-han-js": () => import("./../../../src/pages/projects-han.js" /* webpackChunkName: "component---src-pages-projects-han-js" */),
  "component---src-pages-projects-ideal-js": () => import("./../../../src/pages/projects-ideal.js" /* webpackChunkName: "component---src-pages-projects-ideal-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-lloyds-js": () => import("./../../../src/pages/projects-lloyds.js" /* webpackChunkName: "component---src-pages-projects-lloyds-js" */),
  "component---src-pages-projects-takeaway-js": () => import("./../../../src/pages/projects-takeaway.js" /* webpackChunkName: "component---src-pages-projects-takeaway-js" */),
  "component---src-pages-submission-js": () => import("./../../../src/pages/submission.js" /* webpackChunkName: "component---src-pages-submission-js" */)
}

