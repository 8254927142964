export const mainMenuItems = [
  {
    path: "/",
    text: "Ashley Patricks",
  },
  {
    path: "/about",
    text: "About",
  },
  {
    path: "/projects",
    text: "Projects",
  },
]
