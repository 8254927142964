import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Logo from "../../images/ap.png"

const FooterLogoContainer = () => (
  <FooterLogoStyles>
    <Link to="/">
      <img className="granimate" src={Logo} alt="ghost-footer-logo" />
    </Link>
  </FooterLogoStyles>
)

export const FooterLogoStyles = styled.div`
  text-decoration: none;
  font-weight: bold;
  vertical-align: middle;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  /*.granimate {
    background: "white";
  }*/

  .name-title {
    margin-right: 600px;

    &:hover {
      filter: invert(89%) sepia(25%) saturate(5101%) hue-rotate(103deg)
        brightness(104%) contrast(102%);
      animation: hue 11s linear 0s infinite reverse;

      @keyframes hue {
        to {
          filter: invert(40%) sepia(22%) saturate(3000%) hue-rotate(500deg)
            brightness(111%) contrast(1000%);
        }
      }
    }
  }

  /*.granimate {
    filter: invert(89%) sepia(25%) saturate(5101%) hue-rotate(103deg)
      brightness(104%) contrast(102%);
    animation: hue 11s linear 0s infinite reverse;
  } */

  /*
  .granimate {
    filter: invert(1);
  }
*/
  /* @keyframes hue {
    to {
      filter: invert(40%) sepia(22%) saturate(3000%) hue-rotate(500deg)
        brightness(111%) contrast(1000%);
    }
  }*/

  img {
    height: 60px;
  }
`

export default FooterLogoContainer
