import React, { useState, useEffect } from "react"
import Cookiez from "universal-cookie"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"

import "./reset.css"
import "../styles/cookieConsentStyles.scss"

import Navbar from "./Navbar"
import Footer from "./Ghost-Footer"
import Seo from "../components/Seo/seo"
import StickySubscriber from "./Sticky-Subscription/sticky-subscription"

import GlobalStyles from "../styles/GlobalStyles"
import TypographyStyles from "../styles/TypographyStyles"
import Logo from "../images/ap.png"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false) // Displays modal
  const [isCustomScrollX, setIsCustomScrollX] = useState(false)
  const [cookieSetting, setCookieSetting] = useState(undefined)
  const cookies = new Cookiez()

  useEffect(() => {
    const cookieConsent = getCookieConsentValue("ghost-consent-cookie")
    cookieConsent === undefined
      ? setCookieSetting(false)
      : setCookieSetting(true)
  }, [cookieSetting])

  const handleTheScroll = () => {
    if (window.scrollY > 4000) {
      setIsCustomScrollX(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleTheScroll)

    return () => {
      window.removeEventListener("scroll", handleTheScroll)
    }
  })

  useEffect(() => {
    if (cookies.get("ghosticles-1")) {
      setIsOpen(false) // Modal does not open if cookie exists
    } else if (!cookies.get("ghosticles-1")) {
      setIsOpen(true) // Creates a cookie and shows modal
    }
  }, [])

  return (
    <>
      <Seo />
      <GlobalStyles />
      <TypographyStyles />
      <Navbar Logo={Logo} />
      <div className="layout">{children}</div>
      {/* <CookieConsent
        overlay={true}
        cookieName="ghost-consent-cookie"
        containerClasses="ghost-cookie-consent-container-hybrid"
        buttonClasses="ghost-cookie-consent-button-hybrid"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
      {/* {isOpen && isCustomScrollX && <StickySubscriber />} */}
      <Footer />
    </>
  )
}

export default Layout
