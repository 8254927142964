import React from "react"
import { FaLinkedinIn, FaGithub, FaInstagram } from "react-icons/fa"

export const socialMenuItems = [
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/ashleypatricks/",
    name: "LinkedIn",
  },
  {
    icon: <FaGithub />,
    url: "https://github.com/ashleypatricks",
    name: "GitHub",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/ash_patricks",
    name: "Instagram",
  },
]
