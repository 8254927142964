import React, { useState } from "react"
import Cookies from "universal-cookie"

import LogoContainer from "../Logo/logo"

import "./sticky-subscriber-container.scss"

const StickySubscriber = () => {
  const cookies = new Cookies()
  const [showPopUp, setShowPopUp] = useState(true)

  const handleCookie = () => {
    cookies.set("ghosticles-1", "true", { path: "/" })
    setShowPopUp(false)
  }

  const closeSubscriptionPopup = () => {
    setShowPopUp(false)
  }

  return (
    showPopUp && (
      <div className={`sticky-subscriber-container`}>
        <div className="sticky-subscriber-top-panel">
          <LogoContainer />
          <p onClick={closeSubscriptionPopup}>X</p>
        </div>
        <div className="sticky-subscriber-middle-panel">
          <h4>Get my latest articles sent straight into your inbox!</h4>
        </div>
        <div className="sticky-subscriber-bottom-panel">
          <input type="email" name="email" placeholder="Enter your email" />
          <button onClick={handleCookie}>Send</button>
        </div>
      </div>
    )
  )
}

export default StickySubscriber
