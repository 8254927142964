import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const LogoContainer = () => {
  const query = graphql`
    query {
      fileName: file(relativePath: { eq: "ap.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `
  const data = useStaticQuery(query)

  return (
    <LogoStyles>
      <Link to="/">
        <GatsbyImage
          image={data.fileName.childImageSharp.gatsbyImageData}
          alt="some mad image"
          className="granimate"
        />
      </Link>
    </LogoStyles>
  )
}

export const LogoStyles = styled.div`
  text-decoration: none;
  font-weight: bold;
  vertical-align: middle;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  .name-title {
    margin-right: 600px;

    &:hover {
      filter: invert(89%) sepia(25%) saturate(5101%) hue-rotate(103deg)
        brightness(104%) contrast(102%);
      animation: hue 11s linear 0s infinite reverse;

      @keyframes hue {
        to {
          filter: invert(40%) sepia(22%) saturate(3000%) hue-rotate(500deg)
            brightness(111%) contrast(1000%);
        }
      }
    }
  }

  img {
    height: 60px;
  }
`

export default LogoContainer
