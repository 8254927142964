import React, { useState, useEffect } from "react"
import { mainMenuItems } from "../constants/links"
import { Link } from "gatsby"
import styled from "styled-components"

import LogoContainer from "../components/Logo/logo"

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const [isCustomScroll, setIsCustomScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsCustomScroll(true)
    } else {
      setIsCustomScroll(false)
    }
  }

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const NavStyles = isCustomScroll ? ScrollNavStyles : DefaultNavStyles

  return (
    <NavStyles>
      <div className="masthead flex-container">
        {/* <img src={Logo} alt="Startup Logo" /> */}
        <LogoContainer />
        <button
          className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
          type="button"
          onClick={toggleNav}
          aria-label="Menu Button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <ul
        className={isOpen ? "nav-links show-nav" : "nav-links"}
        onClick={() => setNav(false)}
      >
        {mainMenuItems.map((item, index) => {
          return (
            <li key={index} onClick={() => setNav(false)}>
              <Link to={item.path}>{item.text}</Link>
            </li>
          )
        })}
      </ul>
    </NavStyles>
  )
}

const ScrollNavStyles = styled.nav`
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  padding-bottom: 0;
  padding-top: 0;
  background: rgba(0, 0, 0, 0.875);
  transition: background all 1s ease-in;
  
  .granimate {
    filter: invert(1);
  }

  .masthead {
    z-index: 3;
    width: 100%;
    justify-content: space-between;

    .name-title {
      color: #000000;
    }    

   /* img {
      @media (min-width: 768px) {
        width: 100px;
      }

      @media (min-width: 1200px) {
        width: 120px;
      }
      */
    }
  }

  .nav-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    text-align: center;
    background: rgba(0, 0, 0, 0.875);
    margin: 0;
    margin-right: 150px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    list-style: none;
    padding: 5px 10px 0px 10px;

    // Greater than / equal to iPAD
    @media screen and (min-width: 768px) {
      margin: 0;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0 50px 0 0;
    }

    li {
      list-style: none;
      font-size: 1rem;
      font-weight: 600;
      margin-left: 0;
      padding: 0.75rem 0;

      a {
        text-decoration: none;
        text-transform: capitalize;
        color: #fff;
        transition: 0.3s;

        &.active {
          color: #e609b5;
        }
      }
      &:hover {
        cursor: pointer;
        a {
          border-bottom: 2px white solid;
          padding-bottom: 5px;
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
    }
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .masthead {
      flex-direction: column;
      justify-content: center;

    }

    .toggle-btn {
      display: none;
    }

    .nav-links {
      background: transparent;
      flex-direction: row;
      margin-left: auto;
      position: relative;
      transform: translateX(0);
      transition: none;

      li {
        margin-left: 1rem;
      }
    }
  }

  // @media (min-width: 992px) {
  //   .masthead {
  //     flex-direction: column;
  //     justify-content: center;

  //   }

  //   .toggle-btn {
  //     display: none;
  //   }

  //   .nav-links {
  //     background: transparent;
  //     flex-direction: row;
  //     margin-left: auto;
  //     position: relative;
  //     transform: translateX(0);
  //     transition: none;

  //     li {
  //       margin-left: 1rem;
  //     }
  //   }
  // }
`

const DefaultNavStyles = styled.nav`
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  padding-bottom: 0;
  padding-top: 0;
  /*background-color: black;*/
  /*padding-bottom: 0;*/
  transition: background all 1s ease-in;
 
  .masthead {
    z-index: 3;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0;

    /*img {
      width: 90px;

      @media (min-width: 768px) {
        width: 100px;
      }

      @media (min-width: 1200px) {
        width: 120px;
      }*/
    }
  }

  .nav-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    text-align: center;
    background: rgba(0, 0, 0, 0.875);
    margin: 0;
    margin-right: 150px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    list-style: none;
    padding: 5px 10px 0px 10px;

    // Greater than / equal to iPAD
    @media screen and (min-width: 768px) {
      margin: 0 50px 0 0;
      flex-direction: row;
      justify-content: flex-end;
    }

    li {
      list-style: none;
      font-size: 1rem;
      font-weight: 600;
      margin-left: 0;
      padding: 0.75rem 0;

      a {
        text-decoration: none;
        text-transform: capitalize;
        color: #000;
        transition: 0.3s;

        &.active {
          color: #e609b5;
        }
      }
      &:hover {
        cursor: pointer;
        a {
          border-bottom: 2px black solid;
          padding-bottom: 5px;
          /*color: #e609b5;*/
           /* filter: invert(89%) sepia(25%) saturate(5101%) hue-rotate(103deg)
              brightness(104%) contrast(102%);
            animation: hue 11s linear 0s infinite reverse; */
          
         /* @keyframes hue {
            to {
              filter: invert(40%) sepia(22%) saturate(3000%) hue-rotate(500deg)
                brightness(111%) contrast(1000%);
            }
          } */
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
      a {
          color: #fff;
          &:hover {
           cursor: pointer;
           border-bottom: 2px white solid;
         } 
      }
    }
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #000;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {

        background-color: #fff;

        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
      .masthead {
        flex-direction: column;
        justify-content: center;
      }
  
      .toggle-btn {
        display: none;
      }
  
      .nav-links {
        background: transparent;
        flex-direction: row;
        margin-left: auto;
        position: relative;
        transform: translateX(0);
        transition: none;
  
        li {
          margin-left: 1rem;
        }
      }
    }

  // @media (min-width: 992px) {
  //   .masthead {
  //     flex-direction: column;
  //     justify-content: center;
  //   }

  //   .toggle-btn {
  //     display: none;
  //   }

  //   .nav-links {
  //     background: transparent;
  //     flex-direction: row;
  //     margin-left: auto;
  //     position: relative;
  //     transform: translateX(0);
  //     transition: none;

  //     li {
  //       margin-left: 1rem;
  //     }
  //   }
  // }
`

export default Navbar
