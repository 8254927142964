import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FooterLogoContainer from "./Footer-Logo/footer-logo"

import { socialMenuItems } from "../constants/social-icons"
import { mainMenuItems } from "../constants/links"

const Footer = () => {
  return (
    <FooterStyles>
      <div className="ghost-footer">
        <FooterLogoContainer />
        <p className="ghost-footer-name">Ashley M Patricks </p>
        <div className="ghost-footer-intro">
          <p>
            <span>
              *<br />
            </span>{" "}
            Technical Lead{" "}
            <span>
              <br />*<br />
            </span>{" "}
            Principle Full Stack Software Engineer
            <span>
              <br />*<br />
            </span>{" "}
            Mentor & Teacher
            <span>
              <br />*<br />
            </span>{" "}
          </p>
        </div>
        <div className="ghost-social">
          <div className="ghost-footer-links">
            {mainMenuItems.map((item, _index) => (
              <Link to={item.path}>{item.text}</Link>
            ))}
          </div>
          <div className="ghost-footer-social-icons">
            <ul>
              {socialMenuItems.map((item, index) => (
                <li key={index}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.icon}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="ghost-copyright">
            <small>&copy; Copyright 2022, Ashley Patricks.</small>
          </div>
        </div>
      </div>
    </FooterStyles>
  )
}

const FooterStyles = styled.footer`
  background-color: rgba(0, 0, 0, 0.875);
  padding: calc(var(--spacing) * 2);

  .ghost-footer-name {
    margin-top: 0;
    font-weight: 900;
    color: var(--ghostBlue);
  }

  .ghost-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .ghost-footer-intro {
      display: flex;
      width: 100%;
      max-wdith: 100%;
      align-items: center;
      justify-content: space-around;

      p {
        margin: 0;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }

      p span {
        color: var(--ghostBlue);
        font-weight: 1000;
      }
    }

    .granimate {
      filter: invert(1);
      width: 50px;
      height: 50px;
    }

    img {
      &:hover {
        filter: invert(26%) sepia(84%) saturate(5225%) hue-rotate(232deg)
          brightness(96%) contrast(108%);
      }
    }

    .ghost-footer-social-icons {
      display: flex;
      ul {
        display: flex;
        list-style: none;
        padding: 0;
      }
    }

    .ghost-footer-links {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        // padding-left: 10px;
        // padding-right: 10px;
        padding: 5px 0;
      }
    }

    .ghost-social {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 100%;

      li {
        padding: 5px;
      }

      a {
        text-decoration: none;
        color: #fff;
        transition: var(--transMed);

        &:hover {
          color: var(--ghostBlue);
        }
      }

      .ghost-copyright {
        text-align: center;
      }
    }
  }
`

export default Footer
